import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import {
  faBars,
  faBook,
  faCog,
  faSignOut,
  faUser,
  faUserAlt,
  faUserCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import DropdownMenu from '@components/DropdownMenu';
import DropdownWrapper from '@components/DropdownWrapper';
import {
  brand,
  fontDark,
  fontMedium,
  lineLight,
  secondary,
} from '@constants/colors';
import { laptop, maxWidth } from '@constants/media-queries';
import { navigation } from '@constants/navigation-data';
import { admin, books, login, logout, userAccount } from '@constants/routes';
import { space } from '@constants/spaces';
import Avatar from '@elements/Avatar';
import Button from '@elements/Button';
import TopBar from '@elements/TopBar';
import { useGetAccountInfoQuery } from '@graphql/generated/graphql';
import useClickOutside from '@hooks/useClickOutside';
import useMediaQuery from '@hooks/useMediaQuery';
import zIndex from '@utils/zindex';

type TextLinksProps = {
  open: boolean;
};

const FlexContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
`;

const PageList = styled.li`
  order: 0;
  position: relative;

  ${laptop} {
    display: flex;
    order: 1;
  }

  & > button {
    margin-right: 16px;
  }
`;

const TextLinks = styled(FlexContainer)<TextLinksProps>`
  ${maxWidth.tablet} {
    padding: 16px ${space * 3}px;
    border-right: 1px solid ${lineLight};
    position: absolute;
    background-color: #fff;
    top: -12px; //top level padding
    left: -${space * 3}px; //top level padding
    min-height: 100vh;
    flex-direction: column;
    align-items: center;
    width: 150px;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    transition: transform 300ms ease-out 150ms;
    z-index: ${zIndex('side-menu')};
  }
`;

const TextLink = styled.li`
  margin-bottom: ${space * 3}px;

  ${laptop} {
    margin-left: ${space * 3}px;
    margin-bottom: 0;
  }
  & > a {
    text-decoration: none;
    line-height: 32px;
    &:hover {
      color: ${brand};
    }
  }
`;

const AccountLinks = styled.li`
  order: 2;
  & > ul {
    & > li {
      margin-left: 16px;
      &:first-of-type {
        a {
          &:hover {
            color: ${brand};
          }
        }
      }
    }
  }
`;

const Account = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${space}px;
`;

const StyledButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  border: none;
  color: ${fontMedium};
  font-weight: 500;
  &:hover {
    color: ${fontDark};
    background-color: rgba(0, 0, 0, 0.07);
  }
`;

type Props = {
  backgroundColor?: string;
};

const Navigation = ({ backgroundColor }: Props) => {
  const [showNav, setShowNav] = useState(false);
  const { data } = useGetAccountInfoQuery();
  const wrapperRef = useRef<HTMLUListElement>(null);
  const { isTablet, isLaptop } = useMediaQuery();
  useClickOutside(
    wrapperRef,
    useCallback(() => setShowNav(false), []),
  );

  const accountDropdown = [
    {
      name: 'Mijn boeken',
      to: books,
      icon: faBook,
      show: isTablet,
    },
    {
      name: 'Mijn account',
      href: userAccount,
      icon: faUserAlt,
    },
    {
      name: 'Admin',
      href: admin,
      icon: faCog,
      show: data?.account.role.toUpperCase() === 'ADMIN',
    },
    {
      name: 'Uitloggen',
      href: logout,
      icon: faSignOut,
    },
  ];
  const accountDropdownComponent = <DropdownMenu items={accountDropdown} />;

  const renderLinks = useMemo(
    () =>
      navigation.map((link, i) => (
        <TextLink key={i}>
          <Link href={link.href}>{link.label}</Link>
        </TextLink>
      )),
    [],
  );

  const toggleShowNav = () => {
    setShowNav(!showNav);
  };

  return (
    <TopBar backgroundColor={backgroundColor}>
      <PageList>
        {isTablet && (
          <Button
            set="tertiary"
            onClick={toggleShowNav}
            aria-label="Menu wisselen"
          >
            <FontAwesomeIcon icon={faBars} />
          </Button>
        )}
        <TextLinks ref={wrapperRef} open={showNav}>
          {renderLinks}
        </TextLinks>
      </PageList>
      <AccountLinks>
        <FlexContainer>
          <li>
            {data?.account.firstName ? (
              <DropdownWrapper dropdownChildren={accountDropdownComponent}>
                {isTablet ? (
                  <Button aria-label="Account">
                    <FontAwesomeIcon icon={faUser} />
                  </Button>
                ) : (
                  <Account>
                    <Avatar
                      backgroundColor={secondary}
                      name={data?.account.firstName}
                    />
                    <span>{data?.account.firstName}</span>
                    <FontAwesomeIcon icon={faChevronDown} />
                  </Account>
                )}
              </DropdownWrapper>
            ) : (
              <Link href={login} passHref>
                <Account as="a">
                  <FontAwesomeIcon icon={faUserCircle} />
                  <span>Inloggen</span>
                </Account>
              </Link>
            )}
          </li>
          {isLaptop && (
            <li>
              <Link href={data?.account.firstName ? books : login}>
                <a aria-label="Mijn Boeken">
                  <StyledButton>Mijn Boeken</StyledButton>
                </a>
              </Link>
            </li>
          )}
        </FlexContainer>
      </AccountLinks>
    </TopBar>
  );
};

export default Navigation;
